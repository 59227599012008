import api from '@/services/api'

const LineColor = {
  sales: getComputedStyle(document.documentElement).getPropertyValue('--cui-success').trim(),
  stock: getComputedStyle(document.documentElement).getPropertyValue('--cui-info').trim(),
}

export const customerDataProperties = [
  'firstname',
  'lastname',
  'phone',
]

export function formatTransactionDetails(data, { t, n }) {

  const frequencyMapping = {
    days: {
      1: t('premium.frequency.daily'),
      7: t('premium.frequency.weekly'),
    },
    months: {
      1: t('premium.frequency.monthly'),
    },
    years: {
      1: t('premium.frequency.yearly'),
    }
  }

  const { frequency, frequency_type, transaction_amount } = data
  const period = frequencyMapping[frequency_type]?.[frequency] || `${frequency} ${frequency_type}`

  return `${n(transaction_amount, 'currency')} / ${period}`
}

export function getProductUrl(marketplace, isoCode, productItem) {
  const countryConfig = marketplace.countries.find(v => v.isoCode === isoCode)
  return `${countryConfig?.urls?.productUrlPrefix ?? ''}${productItem.reference}/${countryConfig?.urls.productUrlWithSlug ? useUrlRewrite(productItem.name) : ''}`
}

export function getProductImage(marketplace, isoCode, productItem) {
  const countryConfig = marketplace.countries.find(v => v.isoCode === isoCode)
  if (countryConfig && countryConfig.urls && countryConfig.urls.imagePrefix && productItem.imageUrl) {
    return `${countryConfig.urls.imagePrefix}${productItem.imageUrl}`
  }

  return productItem.imageUrl
}


export function getSalesData(sales, maxSaleDays) {
  const data = []

  const sortedSales = sales.sort((a, b) => new Date(a.date) - new Date(b.date));
  const limitedSales = sortedSales.slice(Math.max(sortedSales.length - maxSaleDays, 0))

  limitedSales.forEach(sale => {
    const date = new Date(sale.date)
    date.setUTCDate(date.getUTCDate() - 1)
    const day = String(date.getUTCDate()).padStart(2, '0')
    const month = date.toLocaleString('es-CO', { month: 'short', timeZone: 'UTC' })

    if (sale.sales < 0) {
      sale.sales = 0
    }

    data.push({
      label: `${day} ${month}`,
      ...sale,
    })
  })

  return data
}

export function formatDateTo(dateString) {
  const date = new Date(dateString)
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('es-CO', options)
}

export function toggleTrace(traced, productId, marketplaceName, isoCode) {
  return new Promise((resolve, reject) => {
    (async () => {
      const newTrace = !traced
      try {
        const action = newTrace ? api.post : api.delete
        const response = await action('/products/tracing', {
          data: {
            productId,
            marketplaceName,
            isoCode,
          }
        })
        resolve({ newTrace, response})
      } catch (err) {
        reject(err)
      }
    })()
  })
}

export function getDataset(data, periodDays, t, type = 'sales') {
  data.slice(Math.max(data.length - periodDays, 0))
  return {
    labels: data.map(v => v.label),
    datasets: [
      {
        label: t(`dashboard.list.body.chart.${type}`),
        backgroundColor: LineColor[type],
        borderColor: LineColor[type],
        pointBackgroundColor: LineColor[type],
        pointBorderColor: LineColor[type],
        data: data.map(v => v[type]),
      },
    ]
  }
}

export function buildSalesData(salesData, periodDays) {
  const price = getPrice(salesData.slice(-1))
  const sales = salesData.slice(-periodDays).reduce((sum, sale) => sum + sale.sales ?? 0, 0)
  const diffPrice = price.suggestedPrice - price.supplierPrice
  return {
    ...price,
    sales,
    earnings: diffPrice > 0 ? sales * diffPrice : '-',
    billing: sales * (price.suggestedPrice > 0 ? price.suggestedPrice : price.supplierPrice),
  }
}

export function getPrice(salesData = null) {
  return {
    suggestedPrice: salesData?.[0]?.suggestedPrice ?? 0,
    supplierPrice: salesData?.[0]?.price ?? 0,
  }
}

export function useUrlRewrite(str) {
  if (!str) return ''

  return str
  .toLowerCase()
  .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  .replace(/[^a-z0-9\s-]/g, '')
  .trim()
  .replace(/\s+/g, '-')
  .replace(/-+/g, '-')
}

export function getAppInfo() {
  return {
    name: 'SpyWinners!',
    author:  {
      name: 'Camilo Orozco',
      website: 'https://www.instagram.com/soycamilorozco/'
    },
    contact: {
      links: {
        instagram: '#',
        x: '#',
        facebook: '#',
        whatsapp: '#',
      }
    },
    copy: new Date().getFullYear(),
  }
}
