import { defineStore } from 'pinia';

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    customerData: {},
  }),
  actions: {
    setCustomerData(data) {
      this.customerData = data;
    },
  },
});
