<script setup>
  /** VUE **/
  import { ref, defineAsyncComponent, onMounted } from 'vue'

  /** THEME **/
  // import '@/views/landing/assets/scss/style.scss'

  /** ROUTE **/
  import { useRoute } from 'vue-router'

  /** COMPONENT VARS **/
  const route = useRoute()
  const planName = ref(null)
  const selectedVariant = ref('main')
  const LandingComponent = ref(null)

  /** METHODS **/

  const moduleExists = async (variant) => {
    try {
      await import(`@/views/landing/variants/${variant}/Index.vue`)
      return true
    } catch (error) {
      return false
    }
  }

  const loadLandingComponent = async variant => {
    const exists = await moduleExists(variant)
    if (exists) {
      selectedVariant.value = variant
    } else {
      planName.value = variant
    }

    LandingComponent.value = defineAsyncComponent(() =>
      import(`@/views/landing/variants/${selectedVariant.value}/Index.vue`)
    )
  }

  /** HOOKS **/
  onMounted(() => {
    const variant = route.params.variant || 'main'
    loadLandingComponent(variant)
  })
</script>

<style lang="scss">
  @import '@/views/landing/assets/scss/style.scss';
</style>

<template>
  <component :is="LandingComponent" :planName="planName" />
</template>
