<template>
  <Loading />
  <router-view />
</template>

<script setup>
  /** VUE **/
  import { onBeforeMount } from 'vue'
  import { useRouter } from 'vue-router'
  /** THEME **/
  import { useColorModes } from '@coreui/vue'
  import { useThemeStore } from '@/stores/theme.js'
  // Loading
  import Loading from '@/components/Loading'

  /** THEME CONSTS **/
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-vue-admin-template-theme')
  const currentTheme = useThemeStore()

  const router = useRouter()

  /** HOOKS **/
  onBeforeMount(() => {
    // const a = true;if (a) return;
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])

    // Validate register
    const action = urlParams.get('action')
    if (action === 'register') {
      const referrer = urlParams.get('ref')
      // If has ref, associate it
      const query = {}
      if (referrer) {
        query.ref = referrer
      }
      // Go to register
      router.push({ name: 'Register', query })
      return
    }

    // THEME
    let theme = urlParams.get('theme')

    if (theme !== null && theme.match(/^[A-Za-z0-9\s]+/)) {
      theme = theme.match(/^[A-Za-z0-9\s]+/)[0]
    }

    if (theme) {
      setColorMode(theme)
      return
    }

    if (isColorModeSet()) {
      return
    }

    setColorMode(currentTheme.theme)
  })

</script>
