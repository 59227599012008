<script setup>
  /** VUE **/
  import { ref, onMounted } from 'vue'
  import { useColorModes } from '@coreui/vue'
  import { useCustomerStore } from '@/stores/customer'
  import { watch } from 'vue'

  /** STORE **/
  const store = useCustomerStore()

  /** WATCH **/
  watch(() => store.customerData, async customerData => {
    customer.value = customerData
  })

  /** COMPONENTES **/
  import AppBreadcrumb from '@/components/AppBreadcrumb'
  import HeaderDropdown from '@/components/HeaderDropdown'
  import { useSidebarStore } from '@/stores/sidebar.js'

  /** COMPONENT VARS **/
  const headerClassNames = ref('mb-4 p-0')
  const { colorMode, setColorMode } = useColorModes('coreui-free-vue-admin-template-theme')
  const sidebar = useSidebarStore()
  const customer = ref(null)

  /** HOOKS **/
  onMounted(() => {
    document.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 0) {
        headerClassNames.value = 'mb-4 p-0 shadow-sm'
      } else {
        headerClassNames.value = 'mb-4 p-0'
      }
    })
  })
</script>

<template>
  <CHeader position="sticky" :class="headerClassNames">
    <CContainer class="border-bottom px-4" fluid>
      <CHeaderToggler @click="sidebar.toggleVisible()" style="margin-inline-start: -14px">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <AppBreadcrumb />
      <CHeaderNav class="ms-auto">
        <!-- COMMING SOON -->
        <CNavItem v-if="false">
          <CNavLink href="#">
            <CIcon icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <span v-if="customer" class="text-body text-opacity-75 px-2 align-content-center" v-text="$t('header.customer.hello', { firstname: customer.firstname })" />
        <li class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </li>
        <CDropdown variant="nav-item" placement="bottom-end">
          <CDropdownToggle :caret="false">
            <CIcon v-if="colorMode === 'dark'" icon="cil-moon" size="lg" />
            <CIcon v-else-if="colorMode === 'light'" icon="cil-sun" size="lg" />
            <CIcon v-else icon="cil-contrast" size="lg" />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem
              :active="colorMode === 'light'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('light')"
            >
              <CIcon class="me-2" icon="cil-sun" size="lg" /> Light
            </CDropdownItem>
            <CDropdownItem
              :active="colorMode === 'dark'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('dark')"
            >
              <CIcon class="me-2" icon="cil-moon" size="lg" /> Dark
            </CDropdownItem>
            <CDropdownItem
              :active="colorMode === 'auto'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('auto')"
            >
              <CIcon class="me-2" icon="cil-contrast" size="lg" /> Auto
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
        <li class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
        </li>
        <HeaderDropdown :colorMode="colorMode" />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>
