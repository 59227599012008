/** VUE **/
import { createApp } from 'vue'
/** i18n **/
import { createI18n } from 'vue-i18n'
import translationList from '@/i18n'
import numberFormat from '@/i18n/numberFormat'
/** ROUTES & STORE **/
import { createPinia } from 'pinia'
import router from '@/router'
/** APP **/
import App from './App.vue'

/** i18n **/
const i18n = createI18n({
  locale: 'es-CO',
  fallbackLocale: 'en',
  messages: translationList,
  numberFormats: numberFormat,
})

/** CREATE APP **/
const app = createApp(App)
app.use(i18n)
app.use(createPinia())
app.use(router)
/** MOUNT **/
app.mount('#app')
