import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useReferrerStore = defineStore('referrer', () => {
  const referrer = ref(null)

  const setReferrer = _referrer => {
    referrer.value = _referrer
  }

  return { referrer, setReferrer }
})
