import db, { getDocs, query, collection, where } from '@/firebase/Firestore'
import FirebaseAuth from '@/firebase/Auth'

export async function getCustomerData() {
  try {
    const user = FirebaseAuth.currentUser
    // Search customer
    const customerQuery = query(collection(db, 'customer'), where('uid', '==', user.uid))
    const customerSnap = await getDocs(customerQuery)

    if (customerSnap.empty) {
      return
    }

    return customerSnap.docs[0].data()
  } catch (error) {
    console.error('ERROR', error)
    return null
  }
}
