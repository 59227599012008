<script setup>
  /** VUE **/
  // import { provide } from 'vue'
  import { ref, onMounted, getCurrentInstance } from 'vue'
  import { useCustomerStore } from '@/stores/customer'
  import { useI18n } from 'vue-i18n'

  /** THEME **/
  import CoreuiVue from '@coreui/vue'
  import CIcon from '@coreui/icons-vue'
  import { iconsSet as icons } from '@/assets/icons'

  /** UTILS **/
  import { getCustomerData } from '@/utils/customer'
  import { customerDataProperties } from '@/utils'

  /** ELEMENTS **/
  import { CContainer } from '@coreui/vue'
  import AppFooter from '@/components/AppFooter.vue'
  import AppHeader from '@/components/AppHeader.vue'
  import AppSidebar from '@/components/AppSidebar.vue'

  // Update customer data
  const { t } = useI18n()
  import UpdateCustomer from '@/components/UpdateCustomer.vue'
  import Notification from '@/components/Notification'

  /** LAYOUT VARS **/
  const needUpdateCustomerData = ref(false)
  const notifications = ref([])
  const customer = ref(null)

  /** STORE **/
  const store = useCustomerStore()

  /** HOOKS **/
  onMounted(() => {
    // Validate if customer has updated info
    validateCustomerData()
  })

  /** PROVIDE **/
  const instance = getCurrentInstance()
  if (instance) {
    instance.appContext.app.component('CIcon', CIcon)
    instance.appContext.app.provide('icons', icons)
    instance.appContext.app.use(CoreuiVue)
  }

  /** METHODS **/
  async function validateCustomerData() {
    try {
      const customerData = await getCustomerData()

      if (!customerData.data || customerDataProperties.some(prop => !customerData.data[prop])) {
        needUpdateCustomerData.value = true
      } else {
        customer.value = {
          email: customerData.email,
          ...customerData.data
        }
        store.setCustomerData(customer.value)
        needUpdateCustomerData.value = false
      }
    } catch (error) {
      console.error('ERROR', error)
    }
  }

  function onSaveCustomerData()
  {
    notifications.value.push({
      title: t('notifications.titles.success'),
      content: t('notifications.content.updated'),
    })
    validateCustomerData()
  }

</script>

<style lang="scss">
  @import '@/assets/styles/default';
  @import 'vue-skeletor/dist/vue-skeletor.css';
</style>

<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <UpdateCustomer v-if="needUpdateCustomerData" v-on:saved="onSaveCustomerData" />
      <div class="body flex-grow-1">
        <CContainer id="layout-container" class="px-3" fluid>
          <router-view v-slot="{ Component }">
            <transition>
              <keep-alive :exclude="['Tracing']">
                <component :is="Component" :key="$route.fullPath" />
              </keep-alive>
            </transition>
          </router-view>
        </CContainer>
      </div>
      <AppFooter />
      <Notification :notifications="notifications" />
    </div>
  </div>
</template>
