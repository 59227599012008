<template>
  <CFooter class="px-4">
    <div>
      <span class="ms-1">&copy; {{ appInfo.copy }}</span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank" v-text="$t('footer.powered')" />
      <a :href="appInfo.author.website" v-text="appInfo.author.name" />
    </div>
  </CFooter>
</template>

<script setup>
  import { getAppInfo } from '@/utils'
  const appInfo = getAppInfo()
</script>
