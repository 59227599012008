<template>
  <CModal
    alignment="center"
    aria-labelledby="renew-dialog"
    backdrop="static"
    :keyboard="false"
    :closable="false"
    :visible="visible"
  >
    <CModalBody>
      <h2 v-text="$t('account.customer.update.title')" />
      <div v-if="formData">
        <div class="mb-3">
          <label class="block text-sm font-medium text-gray-700" v-text="$t('account.customer.update.form.firstname')" />
          <CFormInput v-model="formData.firstname" required />
        </div>
        <div class="mb-3">
          <label class="block text-sm font-medium text-gray-700" v-text="$t('account.customer.update.form.lastname')" />
          <CFormInput v-model="formData.lastname" required />
        </div>
        <div class="mb-3">
          <label class="block text-sm font-medium text-gray-700" v-text="$t('account.customer.update.form.phone')" />
          <VueTelInput
            v-model="formData.phone"
            class="w-full"
            :inputOptions="inputOptions"
          />
        </div>
      </div>
      <CButton
        type="button"
        color="primary"
        class="w-full float-end"
        v-text="$t('account.customer.update.form.save')"
        @click="saveCustomerData"
        :disabled="!validForm || loading"
      />
    </CModalBody>
  </CModal>
</template>

<script setup>
  /** VUE **/
  import { ref, computed, onMounted, defineEmits } from 'vue'
  import { useCustomerStore } from '@/stores/customer'
  import { useI18n } from 'vue-i18n'

  /** FIREBASE **/
  import db, { doc, getDocs, updateDoc, query, collection, where } from '@/firebase/Firestore'
  import FirebaseAuth from '@/firebase/Auth'

  /** UTILS **/
  import { getCustomerData } from '@/utils/customer'
  import { customerDataProperties } from '@/utils'

  /** THEME **/
  import { VueTelInput } from 'vue-tel-input'
  import 'vue-tel-input/vue-tel-input.css'

  /** i18n **/
  const { t } = useI18n()

  /** EMITS **/
  const emit = defineEmits(['saved'])

  /** COMPONENT VARS **/
  const visible = ref(false)
  const loading = ref(false)

  // Form
  const formData = ref({})

  /** STORE **/
  const store = useCustomerStore()

  /** COMPUTED **/
  const inputOptions = computed(() => ({
    placeholder: t('account.customer.update.form.phonePlaceholder'),
    showDialCode: true,
    autoFormat: true,
    validCharactersOnly: true
  }))

  const validForm = computed(() => {
    if (!formData.value) {
      return false
    }
    return !customerDataProperties.some(prop => !formData.value[prop])
  })

  /** HOOKS **/
  onMounted(() => {
    visible.value = true
    buildCustomerData()
  })

  /** METHODS **/
  async function buildCustomerData() {
    // Get customer data
    const customerData = await getCustomerData()
    if (customerData.data) {
      formData.value = customerData.data
    } else {
      formData.value = customerDataProperties.reduce((obj, key) => {
        obj[key] = null
        return obj
      }, {})
    }
  }

  async function saveCustomerData() {
    if (!validForm.value) {
      return
    }
    loading.value = true

    const user = FirebaseAuth.currentUser
    try {
      // Search customer
      const customerQuery = query(collection(db, 'customer'), where('uid', '==', user.uid))
      const customerSnap = await getDocs(customerQuery)

      if (customerSnap.empty) {
        return
      }

      // Ref to save
      const customerId = customerSnap.docs[0].id
      const customerRef = doc(db, 'customer', customerId)
      await updateDoc(customerRef, {
        [`data`]: { ...formData.value }
      })

      emit('saved')
      // State of button
      loading.value = false

      // Set state
      const customerData = await getCustomerData()
      store.setCustomerData({
        email: customerData.email,
        ...customerData.data,
      })
    } catch (error) {
      console.error('Error', error)
      loading.value = false
    }
  }

</script>
