export default {
  'es-CO': {
    currency: {
      style: 'currency',
      currency: 'COP',
      notation: 'standard',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'es-MX': {
    currency: {
      style: 'currency',
      currency: 'MXN',
      notation: 'standard',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  },
  'es-PA': {
    currency: {
      style: 'currency',
      currency: 'PAB',
      notation: 'standard',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  },
  'es-PE': {
    currency: {
      style: 'currency',
      currency: 'PEN',
      notation: 'standard',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  },
  'es-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      notation: 'standard',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  },
  'es-PY': {
    currency: {
      style: 'currency',
      currency: 'PYG',
      notation: 'standard',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  },
  'es-EC': {
    currency: {
      style: 'currency',
      currency: 'USD',
      notation: 'standard',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  },
  en: {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  }
}
